import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import NavigationLink from '../../components/NavigationLink'
import IndividualContactSection from '../../components/IndividualContactSection'
import Image from '../../components/Image'
import Card from '../../components/Card'
import CardDeck from '../../components/CardDeck'

const Page = () => {
  return (
    <React.Fragment>
      <Seo title="Referenz: Kontinuierliche Architekturvalidierung mit jQAssistant bei der Bundesagentur für Arbeit"
           description="Erfahren Sie, wie bei der Bundesagentur für Arbeit kontinuierliche Transparenz und Kontrolle über die Architektur komplexer IT-Systeme und somit ihre langfristige Wartbarkeit mit der Hilfe von jQAssistant sichergestellt wird.."
      />

      <BigTitle
        title="Kontinuierliche Architekturvalidierung mit jQAssistant"
        imageRelativePath={'Titelbild_Architektur.jpg'}
        backgroundColor="#fff"
        className="mask-black"
        textInverted={true}
        menuInverted={true}
        logoInverted={true}
      >
        Case Study - Bundesagentur für Arbeit
      </BigTitle>
      <DefaultSection className="pt-5 pt-lg-0">
        <Article>
          <p>
            Die Bundesagentur für Arbeit (BA) agiert als zentraler Dienstleister am deutschen Arbeitsmarkt. Mit über
            100 eigenen IT-Verfahren betreibt die Bundesagentur für Arbeit eine der größten IT-Landschaften
            Deutschlands.
            Dazu zählt insbesondere auch die Entwicklung und der Betrieb von geschäftskritischen Software-Systemen für
            die
            95.000 Mitarbeiter der Bundesagentur für Arbeit.
          </p>
        </Article>
        <CardDeck className="mt-5 mb-3" cardsPerRow={2}>
          <Card>
            <div className="text-center text-md-left">
              <h2>Technologien</h2>
              <p className="mt-5">
                Continuous Integration, jQAssistant, Java, Architekturvalidierung
              </p>
            </div>
          </Card>
          <Card>
            <div className="text-center text-md-left">
              <h2>Branche</h2>
              <p className="mt-5">
                Öffentlicher Sektor
              </p>
            </div>
          </Card>
        </CardDeck>
        <Article>
          <h2>Die Herausforderung</h2>
          <p>
            Die Implementierung umfangreicher komplexer fachlicher Anforderungen zeichnet einen Großteil der
            Fachanwendungen aus. Regelmäßige gesetzliche Änderungen erfordern eine stetige Anpassung der Software,
            deren Umsetzung innerhalb festgelegter Zeiträume garantiert werden muss. Daraus ergibt sich die
            Notwendigkeit, die Erweiterbarkeit der Systeme selbst bei wachsendem Funktionsumfang termingetreu
            gewährleisten zu können und entsprechende Kosten langfristig kontrollierbar zu halten.
          </p>
          <p>
            Als Herausforderungen erweisen sich in diesem Zusammenhang besonders Komplexität, Umfang und die lange Lauf-
            bzw.
            Entwicklungszeit der Projekte, welche oft über 10 Jahre hinausgeht. Ein erschwerender Faktor ist darüber
            hinaus
            der mit Personalfluktuation einhergehende Wissensverlust über Aufbau bzw. Funktionsweise der Systeme.
          </p>
          <p>
            Trotz dieser Hürden muss aber eine effiziente Weiterentwicklung der Systeme ermöglicht werden, um auch in
            Zukunft
            kurzfristig auf gesetzliche Änderungen reagieren zu können. Darüber hinaus sollen auch durch die
            fortschreitende
            Digitalisierung von Prozessen neue Anforderungen schnell und kostengünstig implementiert werden können.
          </p>
        </Article>
      </DefaultSection>
      <DefaultSection className="bg-level-1">
        <Article>
          <p className="text-muted">
            Die <NavigationLink to={'https://www.arbeitsagentur.de'} ariaLabel={'Website der Arbeitsagentur'}
                                asTextLink>Bundesagentur für Arbeit (BA)</NavigationLink>, mit Sitz in Nürnberg, ist
            eine Körperschaft öffentlichen Rechts und agiert
            als zentraler Dienstleister am deutschen Arbeitsmarkt. Sie ist mit ca. 95.000 Beschäftigten eine der größten
            Behörden in Deutschland und einer der größten Arbeitgeber des Bundes.
          </p>
        </Article>
      </DefaultSection>
      <DefaultSection>
        <Article>
          <h2>Vorgehensweise</h2>
          <p>
            Die Möglichkeit einer langfristigen Erweiterbarkeit komplexer Systeme wird maßgeblich durch den Grad ihrer
            Strukturierung bestimmt. Dazu zählen insbesondere die klare Verortung und einfache Auffindbarkeit fachlicher
            bzw.
            technischer Funktionalitäten in den einzelnen Komponenten der Systeme, die Abschätzbarkeit möglicher
            Auswirkungen
            von Änderungen auf andere Komponenten sowie die Wiedererkennbarkeit und Konsistenz eingesetzter
            Implementierungsmuster.
            Für die aufgezählten Punkte werden daher in der Anwendungsarchitektur entsprechende Konzepte sowie
            Rahmenbedingungen
            definiert, an die beteiligten Entwickler kommuniziert und mit deren Feedback kontinuierlich feinjustiert.
          </p>
          <p>
            <Image relativePath={'Case-Study-BA-Abb1.png'}
                   alt="Entwicklung von Änderungskosten ohne sowie mit kontinuierlicher Architekturvalidierung über die Laufzeit eines Projektes"
                   caption="Entwicklung von Änderungskosten ohne sowie mit kontinuierlicher Architekturvalidierung über die Laufzeit eines Projektes" />
          </p>
          <p>
            Um die korrekte Umsetzung dieser Konzepte sicherzustellen sowie einer schleichenden Erosion von Strukturen
            entgegenzuwirken, wird der Zustand der Architektur kontinuierlich erfasst und validiert.
          </p>
          <h2>Die Lösung</h2>
          <p>
            In Anbetracht des Systemumfangs erfolgt die Architekturvalidierung und -überwachung automatisiert als
            integraler
            Bestandteil des Entwicklungs- bzw. Auslieferungsprozesses. Dieser Ansatz ermöglicht ein direktes Feedback an
            Entwickler bzw. Architekten zur frühzeitigen Erkennung und Vermeidung von teuren Fehlentwicklungen. Ohne den
            Einsatz von geeigneter Software sind Korrekturen nachträglich nur unter erhöhtem Zeit- und Kostenaufwand
            möglich,
            und außerdem mit einem oft nur schwer abschätzbaren Risiko umzusetzen (obere Grafik).
          </p>
          <blockquote className="text-brand">
            <p>
              Mit jQAssistant lässt sich schnell und einfach ein Überblick über ein verteiltes System gewinnen. Durch
              die
              Erweiterbarkeit und Anpassbarkeit können projektspezifische Besonderheiten berücksichtigt werden. Diese
              Eigenschaften
              machen das Tool so mächtig und ja es macht Spaß, damit zu arbeiten.<br /><em
              className="text-muted"><small>Senior Software Architekt</small></em>
            </p>
          </blockquote>
          <p>
            Für die automatisierte Validierung der Architekturkonzepte kommt <NavigationLink
            to={'https://jqassistant.org'} ariaLabel={'Website von jQAssistant'}
            asTextLink>jQAssistant </NavigationLink>
            zum Einsatz. Es handelt sich dabei um ein frei verfügbares Open-Source-Werkzeug zur Analyse und
            Dokumentation von Software-Systemen, welches seit
            2013 maßgeblich durch BUSCHMAIS entwickelt wird. Als eine robuste und skalierbare Lösung hat sich
            jQAssistant
            insbesondere für den Einsatz bei der (Weiter-) Entwicklung komplexer Java-basierter Unternehmensanwendungen
            mit
            umfangreicher Code-Basis bewährt.
          </p>
          <p>Eine besondere Stärke ist die sehr hohe Flexibilität bei der Modellierung von Architekturkonzepten.
            Aufgrund dieser einzigartigen Eigenschaften wird jQAssistant im Entwicklungsprozess für die dauerhafte
            Absicherung von Eigenschaften und Abhängigkeiten fachlicher bzw. technischer Komponenten eingesetzt.
            Aktuelle Einsatz-Szenarien in Projekten bei der Bundesagentur für Arbeit sind:
            <ul>
              <li>Services dürfen nur über EJBs (Enterprise Java Beans) miteinander kommunizieren.
              </li>
              <li>Festgelegte Regeln für den Zugriff zwischen technischen Schichten müssen eingehalten werden, z. B. ist
                der
                Zugriff von Geschäftsobjekten auf die Persistenzschicht gestattet, umgekehrt jedoch verboten.
              </li>
              <li>Geschäftsobjekte müssen zustandslos implementiert werden.
              </li>
            </ul>
          </p>
          <p>
            <Image relativePath={'Case-Study-BA-Abb2.png'}
                   alt="Automatisierte Architekturvalidierung und kontinuierliches Feedback mit jQAssistant im Entwicklungsprozess"
                   caption="Automatisierte Architekturvalidierung und kontinuierliches Feedback mit jQAssistant im Entwicklungsprozess" />
          </p>
          <p>Darüber hinaus wird jQAssistant genutzt, um bei Bedarf Ad-Hoc-Analysen von Software-Systemen vorzunehmen,
            z. B.:
            <ul>
              <li>Ermittlung von Abhängigkeiten zwischen einzelnen Komponenten, um Auswirkungen geplanter Änderungen
                frühzeitig zu erkennen
              </li>
              <li>Auffinden von Code, welcher spezifische Schnittstellen oder Frameworks verwendet
              </li>
              <li>Suche nach inkorrekten Implementierungen anhand von Mustern, z. B. inkorrekte Behandlung von Fehlern
              </li>
            </ul>
          </p>
          <blockquote className="text-brand">
            <p>
              jQAssistant wird eingesetzt, um die Qualität der Architektur sicherzustellen. Der wesentlichste Punkt ist
              die
              Prüfung zur Einhaltung von Architekturvorgaben wie beispielsweise Prüfungen auf die Verletzung der
              vorgegebenen
              Schichtenarchitektur (z. B. Aufrufe aus der Datenschicht in höhere Schichten) und die Prüfung auf
              Verletzung
              der Zustandslosigkeit von Geschäftsobjekten.<br /><em
              className="text-muted"><small>Enterprise System-Architekt - Bundesagentur für Arbeit</small></em>
            </p>
          </blockquote>
          <h2>Der Nutzen</h2>
          <p>
            Die Definition, Umsetzung und Durchsetzung struktureller Konzepte erhöht langfristig die Verständlichkeit
            des
            Systems für die am Projekt beteiligten Entwickler, Architekten und Qualitätsverantwortlichen. Durch
            automatisierte
            Architekturvalidierung wird garantiert, dass dies im Kontext umfangreicher Systeme mit einer großen Anzahl
            von
            Entwicklern sichergestellt werden kann. Potentielle Risiken für Änderungen werden effektiv eingedämmt sowie
            Kosten und Aufwände für Änderungen mittel- bis langfristig stabilisiert oder verringert. Es entstehen eine
            nachhaltige
            Dokumentation und Kommunikation des Architekturwissens im laufenden Entwicklungsbetrieb, welche den
            regelmäßig
            entstehenden Wissensverlust durch Personalfluktuation kompensieren.
          </p>
          <p>
            Weiterhin wird eine kontinuierliche Transparenz über bestehende Abweichungen des Ist- vom Soll-Zustand der
            Architektur
            (Technische Schulden) geschaffen. Diese Informationen stellen eine wertvolle Basis für belastbare
            Architekturentscheidungen
            dar und führen zu einer deutlichen Minimierung von Fehlentscheidungen aufgrund fehlerhafter Annahmen über
            den
            Zustand des Systems.
          </p>
          <p>
            Mit jQAssistant ist ein Architektur-Refactoring während der normalen Weiterentwicklung realisierbar geworden
            und bietet der Bundesagentur für Arbeit die Chance, Wartungskosten dauerhaft zu senken und Risiken für
            Weiterentwicklungen
            zu minimieren.
          </p>
        </Article>
      </DefaultSection>
      <DefaultSection>
        <Article>
          <p>
            <NavigationLink to="/download/Case-Study_Bundesagentur-fuer-Arbeit.pdf" asTextLink
                            ariaLabel="Kontinuierliche Architekturvalidierung mit jQAssistant bei der Bundesagentur für Arbeit">Download
              (PDF)</NavigationLink>
            <br /><small className="text-muted">Stand: 2021</small>
          </p>
        </Article>
      </DefaultSection>
      <IndividualContactSection personKey={'dirk'} />
    </React.Fragment>
  )
}

export default Page
